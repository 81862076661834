import { EResponseCode } from '@/types/websocket'
import { EGamePlayGroundAlertEvent, EGamePlayGroundAlertType } from '@/types/components'

import en_US from '@/locales/en_US.json'
import zh_CN from '@/locales/zh_CN.json'
import zh_TW from '@/locales/zh_TW.json'
import ko_KR from '@/locales/ko_KR.json'
import vi_VN from '@/locales/vi_VN.json'
import th_TH from '@/locales/th_TH.json'
import ja_JP from '@/locales/ja_JP.json'
import ru_RU from '@/locales/ru_RU.json'
import pt_PT from '@/locales/pt_PT.json'
import es_ES from '@/locales/es_ES.json'
import ar_IL from '@/locales/ar_IL.json'

const messages = { en_US, zh_CN, zh_TW, ko_KR, vi_VN, th_TH, ja_JP, ru_RU, pt_PT, es_ES, ar_IL }

// console.log(Object.keys(messages).map(v => ({ code: v, name: messages[v].Language.language_name.body.static })))

export default defineI18nConfig(() => ({
  legacy: false,
  // locale: 'en_US',
  lazy: true,
  locales: Object.keys(messages).map(v => ({ code: v.split('_')[0], iso: v })),
  // defaultLocale: 'en_US',
  fallbackLocale: 'en_US',
  localeDetection: false,
  warnHtmlMessage: false,
  detectBrowserLanguage: false,
  silentTranslationWarn: true,
  // sharedMessages: []
  strategy: 'no_prefix',
  messages,
}))
